import React from 'react';
import { Select } from "@chakra-ui/core";
import "./index.css";

export default function Review(props) {

    const { toggleImage, url, person_id, liveReview, preview, person_data, sizeX, sizeY, showPart, setShowPart, allParts, reviewImage } = props;

    return <div><div style={{ display: "flex" }}>


        {person_data && <img 
            src={props.url}
            height={800}
            width={sizeX}
        />}
                {person_data && (
            <div>
                <div>
                    <img
                        src={
                            !props.toggleImage
                                ? props.url
                                : `https://storage.cloud.google.com/chan_helper/ashish/relabeling/TopCloth_coloured/${props.person_id}_topImage.png`
                        }
                        width="600px"
                        height="800px"
                        style={{
                            opacity: "1",
                        }}
                    />
                    <img
                        src={liveReview}
                        width="600px"
                        height="800px"
                        style={{
                            opacity: "0.5",
                        }}
                        className={"review_image"}
                    />
                </div>
            </div>
        )}
        {person_data && (
            <div>
                <div>
                    <canvas
                        className=""
                        id="livePreviewBig"
                        height={800}
                        width={sizeX}

                        style={{
                            zIndex: "2",
                            display: "flex",
                        }}
                    />

                </div>
            </div>
        )}
    </div>
        {/* <div>
            <Select
                value={showPart}
                onChange={(e) => setShowPart(e.target.value)}
                className={"review_select"}
                style={preview ? {
                    top: "0px",
                } : {
                    opacity: "0.5",
                }}
            >
                <option value={""}>None</option>
                {allParts.map((op) => {
                    if (op["variable"] !== "background") {
                        return (
                            <option
                                style={{ backgroundColor: op["color"] }}
                                value={op["color"]}
                            >
                                {op["name"]}
                            </option>
                        );
                    } else {
                        return (
                            <option
                                style={{
                                    backgroundColor: "rgba(0,0,0,255)",
                                    color: "white",
                                }}
                                value={"rgba(0,0,0,255)"}
                            >
                                {op["name"]}
                            </option>
                        );
                    }
                    return null;
                })}
            </Select>
        </div> */}
    </div>
}