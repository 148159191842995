import React, { useEffect, useState, useRef } from "react";
import * as tf from "@tensorflow/tfjs";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  UPDATE_PERSON_ARM_SEGMENTATION,
  UPDATE_PERSON_HAIR_SEGMENTATION,
  UPDATE_PERSON_RIGHT_ARM_SEGMENTATION,
  GET_PERSON_IMAGE,
  UPDATE_APPROPRIATE,
  UPDATE_OVERLAP_PRESENT,
  GET_GRAPY_SEGMENTATION,
  UPDATE_PERSON_LEFT_ARM_SEGMENTATION,
  // UPDATE_PERSON_LEFT_BARE_ARM_SEGMENTATION,
  // UPDATE_PERSON_RIGHT_BARE_ARM_SEGMENTATION,
  UPDATE_DIFICULTY,
  UPDATE_ARM_POSITION,
  UPDATE_PERSON_BACKGROUND_SEGMENTATION,
} from "./api";
import LoadSegmentedImage from "../LoadSegmentedImg";
import { Radio, RadioGroup, Button, Select as ChakraSelect } from "@chakra-ui/core";
import Select from "react-select"
import mergeImages from "merge-images";
import moment from "moment";
import useKeyPress, {getMousePos, arrangeAllParts, arrangePoints} from '../utility/helper-functions'
import WorkingCanvas from "./WorkingCanvas";
import Review from "./Review"


import {
  allParts,
  UPDATE_PERSON,
  TABLE,
  PRED_GOOD_AVAILABLE,
  OUTPUT_BACKGROUND,
  OUTPUT_BACKGROUND_EXT,
  GET_IMAGE_URL,
  UPDATE_PERSON_NA,
  IGNORE_OPTION,
  UPDATE_PRED_GOOD,
  LINE_WIDTH,
  POINT_WIDTH,
} from "../main";
import "./index.css";
import { parse } from "graphql";
let SCALE = 1;
const OFFSET_X = 0;
const OFFSET_Y = 0;
const SIZE = 600;
let SIZEX = 600;
let SIZEY = 800;
let RATIO = SIZEX / SIZEY;
let imageObj;
let LEFT_POSITION = "1350px";
let TOP_POSITION = "60px";

function rgbToHex(r, g, b) {
  if (r > 255 || g > 255 || b > 255) throw "Invalid color component";
  return ((r << 16) | (g << 8) | b).toString(16);
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function resize(img, size, mask = false) {
  let frame_h = size[0];
  let frame_w = size[1];
  let image_h = img.shape.slice([1], [3])[0];
  let image_w = img.shape.slice([1], [3])[1];
  let h_r = frame_h / image_h;
  let w_r = frame_w / image_w;
  let r = Math.min(h_r, w_r);
  let dim = [Math.round(image_h * r), Math.round(image_w * r)];
  let resized;
  if (mask) {
    resized = tf.image.resizeNearestNeighbor(img, [800, 600], false, true);
  } else {
    resized = tf.image.resizeBilinear(img, [dim[0], dim[1]]);
  }
  // let delta_h = frame_h - resized.shape[1]
  // let delta_w = frame_w - resized.shape[2]
  // let top = Math.floor(delta_h / 2)
  // let bottom = delta_h - Math.floor(delta_h / 2)
  // let left = Math.floor(delta_w / 2)
  // let right = delta_w - Math.floor(delta_w / 2)
  // console.log(resized.shape)
  let z = tf.zeros([800, 600, 1]);
  resized = tf.concat([resized, z], 2);
  resized = tf.util.flatten(resized.arraySync());
  // console.log(resized)
  // let new_im = resized.pad([[0, 0], [top, bottom], [left, right], [0, 0]])
  return resized;
}

function wtb(data, transparent = 0,color = 255, removeRest = true) {
  if (color === 256) {
    for (let i = 0; i < data.length; i += 4) {
      data[i + 3] = 0;
    }
  } else {
    for (let i = 0; i < data.length; i += 4) {
      if (
        data[i] < 30 &&
        data[i + 1] < 30 &&
        // data[i + 1] < 200 &&
        data[i + 2] < 20
      ) {
        data[i + 3] = transparent;
      }
      if(removeRest){
        if (
          data[i] < 230 &&
          data[i] > 190 &&
          data[i+1] < 230 &&
          data[i+1] > 190 &&
          data[i+2] < 230 &&
          data[i+2] > 190 
        ) {
          data[i] = 0;
          data[i + 1] = 0;
          data[i + 2] = 0;
          data[i + 3] = transparent;
        }
      }
    }
  }
}
const MapPoints = (props) => {
  const {
    image,
    load,
    setPicData,
    product_id,
    image_id,
    extraPic,
    canvas_name,
    segmentationData,
    nextImage,
    original_image,
    id,
  } = props;
  let canvas = document.getElementById(canvas_name);
  imageObj = document.getElementById("image");
  const [dataUrl, setDataUrl] = useState("");
  const [preview, setPreview] = useState(false);
  const [opacity, setOpacity] = useState(5);
  const [saveOverlapPresent] = useMutation(UPDATE_OVERLAP_PRESENT);
  const [saveAppropriate] = useMutation(UPDATE_PERSON_NA, {
    onCompleted(data) {
      // console.log("onCompleted: ", data?.update_grapy_correction_14_part.returning[0].is_na)
      // alert(data?.update_grapy_correction_14_part.returning[0].is_na)
      if (data?.[`update_${TABLE}`].returning[0].is_na == "Yes") {
        nextImage();
      } else {
        alert("Image marked as good! Please do the segmentation above.");
      }
    },
  });
  const [showRightCoveredArm, setShowRightCoveredArm] = useState(true);
  const [showBackground, setShowBackground] = useState(true);
  const [showLeftBareArm, setShowLeftBareArm] = useState(true);
  const [showRightBareArm, setShowRightBareArm] = useState(true);
  const [showLeftCoveredArm, setShowLeftCoveredArm] = useState(true);
  const [opacitySeg, setOpacitySeg] = useState(6);
  const [showTorso, setShowTorso] = useState(true);
  const [listMergedImages, setListMergedImages] = useState([]);
  const [initTime, setInitTime] = useState(moment());
  const [predictionData, setPredictionData] = useState();
  const [showSave, setShowSave] = useState(false);
  const [update, setUpdate] = useState(false);
  const [liveReview, setLiveReview] = useState('');
  const [liveP, setLiveP] = useState("");
  const [livePrediction, setLivePrediction] = useState("")
  const connectToFIrstPointKeyDown = useKeyPress("c");
  const deleteLastPointKeyDown = useKeyPress("d");
  const saveKeyDown = useKeyPress("s")
  const personView = useKeyPress("p")
  const [showPart, setShowPart] = useState("");
  const [showPerson, setShowPerson] = useState(false);
  const [deleteOldSegmentation, setDeleteOldSegmentation] = useState(false);
  // const [showRightBareArm, setShowRightBareArm] = useState(true);

  // const show = allParts.map((part)=>part['variable'])

  let DEFAULT_VALUES = {};
  // DEFAULT_VALUES =
  allParts.map((one) => {
    DEFAULT_VALUES[one["variable"]] = "";
  });
  let BINARY_DEFAULT_VALUES = {};
  allParts.map((one) => {
    BINARY_DEFAULT_VALUES[one["variable"]] = "scratch";
  });
  const [formFields, setFormFields] = useState(DEFAULT_VALUES);
  const [imageToMerge, setImageToMerge] = useState({});
  const [edgeImageToMerge, setEdgeImageToMerge] = useState({});

  const onInputChange = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };
  let showTemp = {};
  for (let i = 0; i < allParts.length; i += 1) {
    showTemp[allParts[i]["variable"]] = true;
  }

  const [show, setShow] = useState(showTemp);

  useEffect(() => {
    if (props.loadImage == "segment") {
      setOpacity(10);
    } else {
      setOpacity(5);
    }
  }, [props.loadImage]);

  useEffect(() => {
    if(points[activeIndex].length > 0 && connectToFIrstPointKeyDown){
      lastPointClick()
    }
  }, [connectToFIrstPointKeyDown])

  useEffect(() => {
    if(points[activeIndex].length > 0 && deleteLastPointKeyDown){
      deletePointClick()
    }
  }, [deleteLastPointKeyDown])

  useEffect(() => {
    if(personView){
      setShowPerson(!showPerson)
    }
  }, [personView])

  useEffect(() => {
    if(showPerson){
      setOpacity(10)
    }else{
      setOpacity(4)
    }
  }, [showPerson])

  useEffect(() => {
    if((points[activeIndex].length > 0 || showSave))
    if (points[activeIndex - 1].length > 2 || showSave) {
      // console.log(points[activeIndex -1], activeIndex, 'here')
      if (
        points[activeIndex - 1][0] == lastPoint ||
        points[activeIndex - 1][0] ==
          points[activeIndex - 1][points[activeIndex - 1].length - 1] ||
        showSave
      ) {
        if(!mutationLoading){
          saveSegmentedImage()
        }
      }
    }
  }, [saveKeyDown])

  const { loading, data: person_data } = useQuery(GET_IMAGE_URL, {
    variables: {
      id: id,
    },
  });

  useEffect(() => {
    let pred = new Image();
    pred.src = image;
    pred.crossOrigin = "anonymous";
    // pred.style.imageRendering = "pixelated"
    pred.onload = async () => {
      // URL.revokeObjectURL(pred.src);
      const imageCan = document.createElement("canvas");
      let ctx = imageCan.getContext("2d");
      imageCan.style.width = 600 + "px";
      imageCan.style.height = 800 + "px";
      // var scale = 1;
      // window.devicePixelRatio;
      imageCan.width = Math.floor(600 * 1);
      imageCan.height = Math.floor(800 * 1);
      // ctx.scale(scale, scale);
      ctx.drawImage(
        pred,
        0,
        0,
        pred.naturalWidth,
        pred.naturalHeight,
        0,
        0,
        pred.naturalWidth,
        pred.naturalHeight
      );
      // console.log(imageCan.toDataURL())
      const imageData = ctx.getImageData(
        0,
        0,
        pred.naturalWidth,
        pred.naturalHeight
      );

      // let arr = imageData.data
      // console.log(typeof imageData.data)
      // let ten = tf.tensor(arr)
      // let fin = ten.reshape([256, 192, 3])

      // tf.reshape()

      // console.log(ten.length)
      // console.log(imageData.data.length, 'length')
      let count = 1;
      for (let i = 0; i < imageData.data.length; i += 1) {
        if (
          imageData.data[i] < 30 &&
          imageData.data[i] < 30 &&
          imageData.data[i] < 30
        ) {
          count += 1;
        }
      }
      // console.log(count)
      setPredictionData(imageData.data);
      if (pred.naturalWidth < 600) {
        // alert("Enters")
        // console.log('runs')
        let fin = tf.browser.fromPixels(imageData);
        // console.log(fin.shape)
        let ten = resize(fin, 600, 800, true);
        // console.log(ten.length)
        setPredictionData(ten);
      }
      console.log(tf.memory(), "memory")
      // console.log(predictionData)
    };
    // URL.revokeObjectURL(pred.src);
  }, [image]);

  const [savePredGood] = useMutation(UPDATE_PRED_GOOD, {
    refetchQueries: [{ query: GET_IMAGE_URL, variables: { id: id } }],
  });
  useEffect(() => {
    let fields = {};
    for (const property in DEFAULT_VALUES) {
      fields[property] = person_data[property] || DEFAULT_VALUES[property];
    }
    setFormFields(fields);
    setNaReason(
      person_data[TABLE][0].reason_for_na == null
        ? "No"
        : person_data[TABLE][0].reason_for_na
    );
    // {
    //   collar_type: image_data.collar_type || "",
    // }
  }, [person_data]);
  // const [value, setValue] = React.useState(person_data ? person_data && person_data.tucked_in_segmentation[0].difficulty ?  person_data.tucked_in_segmentation[0].difficulty: 0: 0);
  const [leftArmPosition, setLeftArmPosition] = React.useState("Front");
  const [rightArmPosition, setRightArmPosition] = React.useState("Front");
  const [save_arm_position] = useMutation(UPDATE_ARM_POSITION, {
    onError(err) {
      alert(
        `The arm positions were not saved. Please check your internet connection. For technical purpose: ${err}`
      );
    },
  });
  // const { data: grapy_data } = useQuery(GET_GRAPY_SEGMENTATION, {
  //   variables: {
  //     id: props.cloth_id,
  //   },
  // });

  const [
    save_all,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_PERSON, {
    onCompleted(data) {
      // setPicData(dataUrl);
      if (mutateType == "saved") {
        window.location.reload();
      }
      else{
        setSavedImg(true);
        setShow(false);
      }
    },
    onError(data) {
      if(!mutationLoading){
      alert(
        `Your segmentations did not save. Please check your internet connection.\n For technical purpose: ${data} `
      );
      }
    },
    refetchQueries: [
      {
        query: GET_IMAGE_URL,
        variables: {
          id: id,
        },
      },
    ],
  });

  const [points, setPoints] = useState([[]]);
  const [pointsColor, setPointsColor] = useState([
    { colorSave: "white", colorView: "#8ED6FF", colorPreview: "#aaaaaa", activeVariable: "" },
  ]);

  const [lastPoint, setLastPoint] = useState({});
  const [penultimatePoint, setPenultimatePoint] = useState({});
  const [toggle, setToggle] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [savedImg, setSavedImg] = useState(false);
  const [activeVariable, setActiveVariable] = useState("");
  const [savingPart, setSavingPart] = useState(false);
  const [naReason, setNaReason] = useState("No");
  const [mutateType, setMutateType] = useState("saved");
  const [ignorePart, setIgnorePart] = useState([]);
  const [ignorePartActive, setIgnorePartActive] = useState(false)
  useEffect(() => {
    canvas = document.getElementById(canvas_name);
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "black";
  }, []);

  // useEffect(()=>{
  //   if(person_data){
  //     setValue(person_data.tucked_in_segmentation[0].difficulty?person_data.tucked_in_segmentation[0].difficulty:0)
  //     setLeftArmPosition(person_data.tucked_in_segmentation[0].left_arm_position)
  //     setRightArmPosition(person_data.tucked_in_segmentation[0].right_arm_position)
  //   }
  // },[person_data])

  useEffect(() => {
    canvas = document.getElementById(canvas_name);
    const instaCanvas = canvas;
    let ctx = instaCanvas.getContext("2d");
    canvas.style.width = SIZEX + "px";
    canvas.style.height = SIZEY + "px";
    var scale = 1;
    // window.devicePixelRatio;
    canvas.width = Math.floor(SIZEX * scale);
    canvas.height = Math.floor(SIZEY * scale);
    ctx.scale(scale, scale);
  }, [SIZEX, SIZEY]);

  // useEffect(() => {
  //   // alert('here')
  //   if (points.length > 1 && points[points.length - 2].length == 0) {
  //     canvas = document.getElementById(canvas_name);
  //     const instaCanvas = canvas;
  //     let ctx = instaCanvas.getContext("2d");
  //     canvas.style.width = SIZEX + "px";
  //     canvas.style.height = SIZEY + "px";
  //     var scale = 1;
  //     // window.devicePixelRatio;
  //     canvas.width = Math.floor(SIZEX * scale);
  //     canvas.height = Math.floor(SIZEY * scale);
  //     ctx.scale(scale, scale);
  //     if (activeIndex >= 0) {
  //       for (let j = 0; j < points.length; j++) {
  //         ctx.beginPath();
  //         if (points[j].length > 0) {
  //           ctx.moveTo(points[j][0].x, points[j][0].y);
  //         }
  //         for (let i = 1; i < points[j].length; i++) {
  //           // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
  //           ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
  //         }
  //         ctx.lineWidth = 2;
  //         ctx.fillStyle = pointsColor[j].colorPreview;
  //         // debugger
  //         ctx.strokeStyle = pointsColor[j].colorPreview;
  //         ctx.fill();
  //         ctx.stroke();
  //       }
  //     }
  //     const presentData = canvas.toDataURL();
  //     let list = [presentData];
  //     Object.keys(imageToMerge).forEach((key) => {
  //       if (imageToMerge[key] == "save") {
  //         list.push(person_data[TABLE][0][key]);
  //       }
  //     });
  //     createImage(ctx, list);
  //     ctx.beginPath();
  //     ctx.stroke();
  //     // alert('Enters')
  //   }
  // }, [imageToMerge]);

  useEffect(() => {
    // if (typeof lastPoint == "object" && Object.keys(lastPoint).length > 0) {
    // console.log

    if (person_data && !mutationLoading) {
      canvas = document.getElementById(canvas_name);
      // console.log(canvas.toDataURL())
      const instaCanvas = canvas;
      let ctx = instaCanvas.getContext("2d");
      canvas.style.width = SIZEX + "px";
      canvas.style.height = SIZEY + "px";
      var scale = 1;
      // window.devicePixelRatio;
      canvas.width = Math.floor(SIZEX * scale);
      canvas.height = Math.floor(SIZEY * scale);
      ctx.scale(scale, scale);

      let list = [];
      // Object.keys(allParts).forEach((key) => {
      //   // if (imageToMerge[key["variable"]] != "scratch" && person_data[TABLE][0][key["variable"]]!=null) {
      //   //   list.push(person_data[TABLE][0][key["variable"]]);
      //   // }
      //   // console.log(key)
      //   console.log(imageToMerge)
      //   if(imageToMerge[allParts[key]["variable"]] && imageToMerge[allParts[key]["variable"]] != "scratch"){
      //     // alert(imageToMerge[allParts[key]["variable"]])
      //     list.push(person_data[TABLE][0][allParts[key]["variable"]])
      //   }
      // });
      let arrangedAllParts = arrangeAllParts(allParts)
      Object.keys(arrangedAllParts).forEach((key) => {
        // if (imageToMerge[key["variable"]] != "scratch" && person_data[TABLE][0][key["variable"]]!=null) {
        //   list.push(person_data[TABLE][0][key["variable"]]);
        // }
        // console.log(key)
        if (
          imageToMerge[arrangedAllParts[key]["variable"]] &&
          imageToMerge[arrangedAllParts[key]["variable"]] != "scratch"
        ) {
          // alert(imageToMerge[arrangedAllParts[key]["variable"]])
          list.push(person_data[TABLE][0][arrangedAllParts[key]["variable"]]);
        } else if (
          imageToMerge[arrangedAllParts[key]["variable"]] &&
          imageToMerge[arrangedAllParts[key]["variable"]] != "scratch" &&
          person_data[TABLE][0][arrangedAllParts[key]["variable"]] !== null
        ) {
          list.push(person_data[TABLE][0][arrangedAllParts[key]["variable"]]);
        } else {
          if (!imageToMerge[arrangedAllParts[key]["variable"]]) {
            list.push(person_data[TABLE][0][arrangedAllParts[key]["variable"]]);
          }
        }
      });

      Object.keys(edgeImageToMerge).forEach((key) => {
        if (edgeImageToMerge[key]) {
          list = list.concat(edgeImageToMerge[key]);
        }
      });
      createImage(ctx, list);
      // make_base(ctx, person_data[TABLE][0][activeVariable])
      if (activeIndex > 0) {
        for (let j = 0; j < points.length - 1; j++) {
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            ctx.fillRect(
              points[j][i].x - OFFSET_X,
              points[j][i].y - OFFSET_Y,
              POINT_WIDTH,
              POINT_WIDTH
            );
            // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = LINE_WIDTH;
          ctx.globalAlpha = 0.7;
          ctx.fillStyle = pointsColor[j].colorView;
          ctx.fill();
          ctx.stroke();
        }
      }
      if (penultimatePoint) {
        ctx.beginPath();
        if (points[activeIndex].length > 0) {
          ctx.moveTo(points[0].x, points[0].y);
          ctx.fillRect(
            points[activeIndex][0].x - OFFSET_X,
            points[activeIndex][0].y - OFFSET_Y,
            POINT_WIDTH,
            POINT_WIDTH
          );
        }
        for (let i = 0; i < points[activeIndex].length; i++) {
          ctx.fillRect(
            points[activeIndex][i].x - OFFSET_X,
            points[activeIndex][i].y - OFFSET_Y,
            POINT_WIDTH,
            POINT_WIDTH
          );
          // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
          ctx.lineTo(
            points[activeIndex][i].x - OFFSET_X,
            points[activeIndex][i].y - OFFSET_Y
          );
        }
        if (toggle) {
          ctx.lineWidth = LINE_WIDTH;
          ctx.globalAlpha = 0.7;
          ctx.fillStyle = pointsColor[activeIndex].colorView;
          ctx.fill();
        }
        ctx.stroke();
      }
      // }
    }
  }, [lastPoint, activeVariable, imageToMerge, person_data, opacity, update]);

  const capturePoint = (evt) => {
    // console.log(evt)
    if (activeIndex == 0) {
      alert("Please select the body part that you want to color");
      return;
    }
    const mousePos = getMousePos(canvasRef, evt, lastPoint);
    if (mousePos){
      if ( (mousePos.x >= 0 || mousePos.x <= 600) && (mousePos.y >= 0 || mousePos.y <= 800)){
        const temp = points;
        temp[activeIndex].push(mousePos);
        setPoints(temp);
        setPenultimatePoint(lastPoint);
        setLastPoint(mousePos);
        setToggle(false);
      }
    }

  };

  const capturePointOutside = (evt) => {
    // alert("outside click")
    const mousePos = getMousePos(containerRef, evt, lastPoint);
    if(mousePos){
      if ( mousePos.x == 0 || mousePos.x == 600 || mousePos.y == 0 || mousePos.y == 800){
        if (activeIndex == 0) {
          alert("Please select the body part that you want to color");
          return;
        }
        const temp = points;
        temp[activeIndex].push(mousePos);
        setPoints(temp);
        setPenultimatePoint(lastPoint);
        setLastPoint(mousePos);
        setToggle(false);
        console.log(mousePos, "mousemove")
      }
    }
    
  }

  const lastPointClick = () => {
    setToggle(true);
    let temp = points;
    let tempPoints = points[activeIndex];
    tempPoints = [...tempPoints, tempPoints[0]];
    temp[activeIndex] = tempPoints;
    temp.push([[]]);
    let tempColors = pointsColor;
    tempColors = [...tempColors, pointsColor[activeIndex]];
    setPoints(temp);
    setPointsColor(tempColors);
    setLastPoint(points[activeIndex][0]);
    setActiveIndex(activeIndex + 1);
    previewSegmentedImage();
  };

  const deletePointClick = () => {
    const temp = points;
    if (points[activeIndex].length === 1) {
      if (activeIndex > 0) {
        const temp = points.slice(0, points.length - 1);
        const tempColor = pointsColor.slice(0, pointsColor.length - 1);
        setToggle(false);
        setPoints([...temp]);
        setPointsColor([...tempColor]);
        setLastPoint(
          points[activeIndex - 1][points[activeIndex - 1].length - 1]
        );
        setActiveIndex(activeIndex - 1);
        setUpdate(!update);
      } else {
        const tempColor = pointsColor.slice(0, pointsColor.length - 1);
        setActiveIndex(0);
        setPoints([[]]);
        setPointsColor([...tempColor]);
        setToggle(false);
        setLastPoint({});
      }
    } else {
      const tempPoints = points[activeIndex].slice(
        0,
        points[activeIndex].length - 1
      );
      temp[activeIndex] = tempPoints;
      setToggle(false);
      setPoints([...temp]);
      setLastPoint(points[activeIndex][points[activeIndex].length - 2]);
    }
  };

  const showClicked = (part) => {
    let showTemp = {};
    if (part == "all") {
      for (let i = 0; i < allParts.length; i += 1) {
        showTemp[allParts[i]["variable"]] = true;
      }
    } else {
      for (let i = 0; i < allParts.length; i += 1) {
        showTemp[allParts[i]["variable"]] = false;
      }
      showTemp[part] = true;
    }
    setShow(showTemp);
  };

  function toDataURL(src, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      // console.log("this: ",this.naturalWidth, this.naturalHeight)
      ctx.drawImage(
        this,
        0,
        0,
        this.naturalWidth,
        this.naturalHeight,
        0,
        0,
        SIZEX / 2,
        SIZEY / 2
      );
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  }

  const livePreview = async () => {
    // console.log("allParts: ", allParts);

    let list = [];
    let listName =  []
    let arrangedAllParts = arrangeAllParts(allParts)

    for (let i = 0; i < arrangedAllParts.length; i++) {
      let seg = person_data[TABLE][0][arrangedAllParts[i]["variable"]];
      if (seg != null || seg != undefined) {
        list.push(seg);
        listName.push(arrangedAllParts[i]["variable"])
      }
    }
    console.log("list: ", list.length, listName)

    // await toDataURL(
    //   image,
    //   async function(dataUrl) {
    //     console.log('RESULT:', dataUrl)
    //     list.unshift(dataUrl);
    //     console.log("list: ", list.length, list);
    //     await mergeImages(list).then((b64) => {
    //       // alert(allParts[i]["variable"])
    //       console.log("list b64: ", b64)
    //       // console.log(b64, allParts[i]["variable"])
    //       // allVariables[allParts[i]["variable"]] = b64;

    //       var canvas = document.getElementById("livePreview");
    //       var ctx = canvas.getContext("2d");

    //       var image = new Image();
    //       image.onload = function() {
    //         // SIZEX / 2
    //         ctx.drawImage(
    //           image,
    //           0,
    //           0,
    //           image.naturalWidth,
    //           image.naturalHeight,
    //           0,
    //           0,
    //           SIZEX/2,
    //           SIZEY/2
    //         );
    //       };
    //       image.src = b64;
    //     });
    //   }
    // )
    let predImg = new Image();
    predImg.src = image;
    predImg.crossOrigin = "anonymous";
    predImg.onload = async function () {
      if(person_data[TABLE][0]["prediction_type"]!=="scratch"){
      let predCanvas = document.createElement("canvas");
      predCanvas.width = SIZEX;
      predCanvas.height = SIZEY;
      let ctx2 = predCanvas.getContext("2d");
      // ctx2.drawImage(predImg, 0, 0, predImg.naturalWidth, predImg.naturalHeight, 0, 0, SIZEX, SIZEY);
      ctx2.drawImage(
        predImg,
        0,
        0,
        predImg.naturalWidth,
        predImg.naturalHeight,
        0,
        0,
        SIZEX,
        SIZEY
      );
      let data = predCanvas.toDataURL();
      // console.log("predCanvas data: ", data, predImg.naturalWidth, predImg.naturalHeight);
      list.unshift(data);
      }

      await mergeImages(list).then((b64) => {
        // alert(allParts[i]["variable"])
        // console.log("list b64: ", b64)
        // console.log(b64, allParts[i]["variable"])
        // allVariables[allParts[i]["variable"]] = b64;

        setLiveP(b64)
        var canvas = document.getElementById("livePreview");
        var ctx = canvas.getContext("2d");

        var image = new Image();
        image.onload = function () {
          // SIZEX / 2
          ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            SIZEX / 2,
            SIZEY / 2
          );
        };
        image.src = b64;

        var canvasBig = document.getElementById("livePreviewBig");
        var ctxBig = canvasBig.getContext("2d");

        var imageBig = new Image();
        imageBig.onload = function () {
          // SIZEX / 2
          ctxBig.drawImage(
            imageBig,
            0,
            0,
            imageBig.naturalWidth,
            imageBig.naturalHeight,
            0,
            0,
            SIZEX,
            SIZEY
          );

          const imageData = ctxBig.getImageData(0, 0, 600, 800);

          wtb(imageData.data,255);
          ctxBig.putImageData(imageData, 0, 0);
        };
        imageBig.src = b64;
      });
    };
    // console.log("list: ", list.length, list)
  };

  useEffect(() => {
    livePreview();
  }, [person_data, props.loadImage]);

  useEffect(() => {
    if(liveP!==""){
      let newDummyImg = new Image();
      newDummyImg.src = liveP;
      newDummyImg.crossOrigin = "anonymous";
      newDummyImg.onload = () => {
        URL.revokeObjectURL(newDummyImg.src);
        // list.push(newDummyImg.src)
        // let src = mergeImages(list)
        canvas = document.createElement("canvas");
        const instaCanvas = canvas;
        let ctx = instaCanvas.getContext("2d");
        canvas.style.width = SIZEX + "px";
        canvas.style.height = SIZEY + "px";
        var scale = 1;
        // window.devicePixelRatio;
        canvas.width = Math.floor(SIZEX * scale);
        canvas.height = Math.floor(SIZEY * scale);
        ctx.scale(scale, scale);
        // let adimg = new Image()
        // adimg.src = src
        // adimg.crossOrigin = "anonymous"
        ctx.drawImage(
          newDummyImg,
          0,
          0,
          newDummyImg.naturalWidth,
          newDummyImg.naturalHeight,
          0,
          0,
          SIZEX,
          SIZEY
        );

        const imageData = ctx.getImageData(0, 0, 600, 800);

        wtb(imageData.data);
        ctx.putImageData(imageData, 0, 0);
        const picURL = canvas.toDataURL();
        setLiveReview(picURL)
      }
    }
  }, [liveP]);

  useEffect(() => {
    if(image!==""){
      let newDummyImg = new Image();
      newDummyImg.src = image;
      newDummyImg.crossOrigin = "anonymous";
      newDummyImg.onload = () => {
        URL.revokeObjectURL(newDummyImg.src);
        // list.push(newDummyImg.src)
        // let src = mergeImages(list)
        canvas = document.createElement("canvas");
        const instaCanvas = canvas;
        let ctx = instaCanvas.getContext("2d");
        canvas.style.width = SIZEX + "px";
        canvas.style.height = SIZEY + "px";
        var scale = 1;
        // window.devicePixelRatio;
        canvas.width = Math.floor(SIZEX * scale);
        canvas.height = Math.floor(SIZEY * scale);
        ctx.scale(scale, scale);
        // let adimg = new Image()
        // adimg.src = src
        // adimg.crossOrigin = "anonymous"
        ctx.drawImage(
          newDummyImg,
          0,
          0,
          newDummyImg.naturalWidth,
          newDummyImg.naturalHeight,
          0,
          0,
          SIZEX,
          SIZEY
        );

        const imageData = ctx.getImageData(0, 0, 600, 800);

        wtb(imageData.data);
        ctx.putImageData(imageData, 0, 0);
        const picURL = canvas.toDataURL();
        // console.log(picURL, "liveP")
        setLivePrediction(picURL)
      }
    }
  }, [image]);

  const savePart = (canvas, colorToSave, variable) => {
    let flag = false;
    let ctx = canvas.getContext("2d");
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, SIZEX, SIZEY);
    ctx.restore();
    // ctx.fillStyle = "rgba(0, 0, 0, 0)";
    // ctx.fillRect(0, 0, SIZEX, SIZEY);
    const picUR = canvas.toDataURL();
    // console.log(picUR, "while Saving", variable)
    if (activeIndex >= 0) {
      for (let j = 0; j < points.length; j++) {
        if (pointsColor[j].colorPreview == colorToSave) {
          // console.log(j);
          if (flag === false) {
            flag = true;
          }
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = LINE_WIDTH;
          ctx.fillStyle = pointsColor[j].colorPreview;
          // debugger
          ctx.strokeStyle = pointsColor[j].colorPreview;
          ctx.fill();
          ctx.stroke();
        }
      }
    }
    const picURL = canvas.toDataURL();
    setSavingPart(!savingPart);
    if (flag == false) {
      return null;
    } else {
      return picURL;
    }
  };

  function make_base(ctx, src) {
    let base_image = new Image();
    base_image.src = src;
    base_image.onload = function () {
      URL.revokeObjectURL(base_image.src);
      ctx.drawImage(base_image, 0, 0, SIZEX, SIZEY);
    };
  }

  function createImage(ctx, list) {
    let newlist = [];
    for (let i = 0; i < list.length; i += 1) {
      if (list[i] !== null && list[i] !== undefined) {
        newlist.push({
          src: list[i],
          opacity: 0.5,
        });
      }
    }
    mergeImages(newlist).then(async (b64) => {
      await make_base(ctx, b64);
    });
  }

  const saveSegmentedImage = async () => {
    canvas = document.getElementById(canvas_name);
    const instaCanvas = canvas;
    let ctx = instaCanvas.getContext("2d");
    canvas.style.width = SIZEX + "px";
    canvas.style.height = SIZEY + "px";
    var scale = 1;
    // window.devicePixelRatio;
    canvas.width = Math.floor(SIZEX * scale);
    canvas.height = Math.floor(SIZEY * scale);
    ctx.scale(scale, scale);
    // if(props.loadImage=='segment'){
    // ctx.clearRect(0, 0, SIZEX, SIZEY);
    // ctx.fillStyle = "rgba(0, 0, 0, 0)";
    // ctx.fillRect(0, 0, SIZEX, SIZEY);

    // console.log(canvas.toDataURL())

    let allVariables = {};
    allVariables["id"] = id;
    // ****************** ALL ******************* //
    let newVariables = {};
    for (let i = 0; i < allParts.length; i += 1) {
      const pic = await savePart(
        canvas,
        allParts[i]["color"],
        allParts[i]["variable"]
      );
      // console.log(pic,allParts[i]["variable"])
      if (pic != null) {
        if (
          person_data[TABLE][0][allParts[i]["variable"]] &&
          imageToMerge[allParts[i]["variable"]] == "save"
        ) {
          let list = [];
          if (
            edgeImageToMerge[allParts[i]["variable"]] != null ||
            edgeImageToMerge[allParts[i]["variable"]] != undefined
          ) {
            list = list.concat(edgeImageToMerge[allParts[i]["variable"]]);
          }
          list.push(person_data[TABLE][0][allParts[i]["variable"]]);
          list.push(pic);
          await mergeImages(list).then((b64) => {
            // alert(allParts[i]["variable"])
            // console.log(b64, allParts[i]["variable"]);
            allVariables[allParts[i]["variable"]] = b64;
          });
        } else {
          // alert('Nope')
          let list = [];
          if (
            edgeImageToMerge[allParts[i]["variable"]] != null ||
            edgeImageToMerge[allParts[i]["variable"]] != undefined
          ) {
            // console.log(edgeImageToMerge[allParts[i]["variable"]]);
            list = list.concat(edgeImageToMerge[allParts[i]["variable"]]);
          }
          list.push(pic);
          await mergeImages(list).then((b64) => {
            // alert(allParts[i]["variable"])
            // console.log(
            //   "pic was not null but no save",
            //   b64,
            //   allParts[i]["variable"]
            // );
            allVariables[allParts[i]["variable"]] = b64;
          });
        }
      } else {
        let list = [];
        if (
          edgeImageToMerge[allParts[i]["variable"]] != null ||
          edgeImageToMerge[allParts[i]["variable"]] != undefined
        ) {
          // console.log(edgeImageToMerge[allParts[i]["variable"]]);
          list = list.concat(edgeImageToMerge[allParts[i]["variable"]]);
        }
        if (person_data[TABLE][0][allParts[i]["variable"]] != null) {
          list.push(person_data[TABLE][0][allParts[i]["variable"]]);
        }
        if (list.length > 0) {
          // console.log(list);
          await mergeImages(list).then((b64) => {
            // console.log("pic was null", b64, allParts[i]["variable"]);
            // alert(allParts[i]["variable"])
            allVariables[allParts[i]["variable"]] = b64;
          });
        } else {
          // console.log(null, allParts[i]["variable"]);
          allVariables[allParts[i]["variable"]] = null;
        }
      }
    }
    allVariables["name"] = props.name;
    // console.log(props, "name");
    allVariables["seconds_spent"] = moment().diff(initTime, "seconds");
    allVariables["last_updated"] = moment()
    allVariables["worst_case_labelled"] = person_data[TABLE][0]["worst_case_labelled"] ? "Labelled" : null;
    // console.log(moment().diff(initTime, "seconds"));
    setMutateType("saved");
    allVariables["prediction_type"] = props.loadImage;
    await save_all({
      variables: allVariables,
    });

    // let newDummyImg = new Image();
    // newDummyImg.src = image;
    // newDummyImg.crossOrigin = "anonymous";
    // newDummyImg.onload = async () => {
    //   URL.revokeObjectURL(newDummyImg.src);
    //   canvas = document.getElementById(canvas_name);
    //   // const instaCanvas = canvas;
    //   let ctx = canvas.getContext("2d");
    //   ctx.drawImage(
    //     newDummyImg,
    //     0,
    //     0,
    //     newDummyImg.naturalWidth,
    //     newDummyImg.naturalHeight,
    //     0,
    //     0,
    //     SIZEX,
    //     SIZEY
    //   );
    //   if (activeIndex >= 0) {
    //     for (let j = 0; j < points.length; j++) {
    //       ctx.beginPath();
    //       if (points[j].length > 0) {
    //         ctx.moveTo(points[j][0].x, points[j][0].y);
    //       }
    //       for (let i = 1; i < points[j].length; i++) {
    //         // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
    //         ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
    //       }
    //       ctx.lineWidth = 2;
    //       ctx.fillStyle = pointsColor[j].colorPreview;
    //       // debugger
    //       ctx.strokeStyle = pointsColor[j].colorPreview;
    //       ctx.fill();
    //       ctx.stroke();
    //     }
    //   }
    //   const imageData = ctx.getImageData(0, 0, 600, 800);
    //   wtb(imageData.data);
    //   ctx.putImageData(imageData, 0, 0);
    // };

    // ctx.fillStyle = "black";
    // ctx.fillRect(0, 0, SIZEX, SIZEY);
    // if (activeIndex >= 0) {
    //   for (let j = 0; j < points.length; j++) {
    //     ctx.beginPath();
    //     if (points[j].length > 0) {
    //       ctx.moveTo(points[j][0].x, points[j][0].y);
    //     }
    //     for (let i = 1; i < points[j].length; i++) {
    //       ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
    //     }
    //     ctx.lineWidth = 2;
    //     ctx.fillStyle = pointsColor[j].colorSave;
    //     // debugger
    //     ctx.strokeStyle = pointsColor[j].colorSave;
    //     ctx.fill();
    //     ctx.stroke();
    //   }
    // }
  };

  const previewSegmentedImage = async () => {
    canvas = document.getElementById("preview_canvas");
    const instaCanvas = canvas;
    let ctx = instaCanvas.getContext("2d");
    canvas.style.width = SIZEX + "px";
    canvas.style.height = SIZEY + "px";
    var scale = 1;
    // window.devicePixelRatio;
    canvas.width = Math.floor(SIZEX * scale);
    canvas.height = Math.floor(SIZEY * scale);
    let list = [];
    let arrangedAllParts = arrangeAllParts(allParts)

    Object.keys(arrangedAllParts).forEach((key) => {
      // if (imageToMerge[key["variable"]] != "scratch" && person_data[TABLE][0][key["variable"]]!=null) {
      //   list.push(person_data[TABLE][0][key["variable"]]);
      // }
      // console.log(key)
      // console.log(imageToMerge)
      if (
        imageToMerge[arrangedAllParts[key]["variable"]] &&
        imageToMerge[arrangedAllParts[key]["variable"]] != "scratch"
      ) {
        // alert(imageToMerge[arrangedAllParts[key]["variable"]])
        if (person_data[TABLE][0][arrangedAllParts[key]["variable"]]) {
          list.push(person_data[TABLE][0][arrangedAllParts[key]["variable"]]);
        }
      } else if (
        imageToMerge[arrangedAllParts[key]["variable"]] &&
        imageToMerge[arrangedAllParts[key]["variable"]] != "scratch" &&
        person_data[TABLE][0][arrangedAllParts[key]["variable"]] !== null
      ) {
        list.push(person_data[TABLE][0][arrangedAllParts[key]["variable"]]);
      } else {
        if (
          !imageToMerge[arrangedAllParts[key]["variable"]] &&
          person_data[TABLE][0][arrangedAllParts[key]["variable"]] != null
        ) {
          list.push(person_data[TABLE][0][arrangedAllParts[key]["variable"]]);
        }
      }
    });

    Object.keys(edgeImageToMerge).forEach((key) => {
      if (edgeImageToMerge[key]) {
        list = list.concat(edgeImageToMerge[key]);
      }
    });

    ctx.scale(scale, scale);
    if (props.loadImage != "segment") {
      let newDummyImg = new Image();
      newDummyImg.src = image;
      newDummyImg.crossOrigin = "anonymous";
      newDummyImg.onload = () => {
        URL.revokeObjectURL(newDummyImg.src);
        // list.push(newDummyImg.src)
        // let src = mergeImages(list)
        canvas = document.getElementById("preview_canvas");
        const instaCanvas = canvas;
        let ctx = instaCanvas.getContext("2d");
        // let adimg = new Image()
        // adimg.src = src
        // adimg.crossOrigin = "anonymous"
        ctx.drawImage(
          newDummyImg,
          0,
          0,
          newDummyImg.naturalWidth,
          newDummyImg.naturalHeight,
          0,
          0,
          SIZEX,
          SIZEY
        );
        // ctx.drawImage(
        //   adimg,
        //   0,
        //   0,
        //   SIZEX,
        //   SIZEY
        // )
        let [finalPoints, finalPointsColor] = arrangePoints(points, pointsColor)

        if (activeIndex >= 0) {
          for (let j = 0; j < finalPoints.length; j++) {
            ctx.beginPath();
            if (finalPoints[j].length > 0) {
              ctx.moveTo(finalPoints[j][0].x, finalPoints[j][0].y);
            }
            for (let i = 1; i < finalPoints[j].length; i++) {
              // ctx.moveTo(finalPoints[i - 1].x - OFFSET_X, finalPoints[i - 1].y - OFFSET_Y);
              ctx.lineTo(finalPoints[j][i].x - OFFSET_X, finalPoints[j][i].y - OFFSET_Y);
            }
            ctx.lineWidth = LINE_WIDTH;
            ctx.fillStyle = finalPointsColor[j].colorPreview;
            // debugger
            ctx.strokeStyle = finalPointsColor[j].colorPreview;
            ctx.fill();
            ctx.stroke();
          }
        }
        const imageData = ctx.getImageData(0, 0, 600, 800);
        wtb(imageData.data);
        ctx.putImageData(imageData, 0, 0);
        const picURL = canvas.toDataURL();
        // list.push(picURL);
        list.unshift(picURL);
        let finalImage = new Image();
        finalImage.crossOrigin = "anonymous";
        mergeImages(list).then((b64) => {
          finalImage.src = b64;
          ctx.drawImage(finalImage, 0, 0, SIZEX, SIZEY);
          setDataUrl(b64);
        });

        LEFT_POSITION = "2000px";
        setPreview(true);
      };
    }
    // else {
    //   if (activeIndex >= 0) {
    //     for (let j = 0; j < points.length; j++) {
    //       ctx.beginPath();
    //       if (points[j].length > 0) {
    //         ctx.moveTo(points[j][0].x, points[j][0].y);
    //       }
    //       for (let i = 1; i < points[j].length; i++) {
    //         // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
    //         ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
    //       }
    //       ctx.lineWidth = 2;
    //       ctx.fillStyle = pointsColor[j].colorPreview;
    //       // debugger
    //       ctx.strokeStyle = pointsColor[j].colorPreview;
    //       ctx.fill();
    //       ctx.stroke();
    //     }
    //   }
    //   const imageData = ctx.getImageData(0, 0, 600, 800);
    //   wtb(imageData.data);
    //   ctx.putImageData(imageData, 0, 0);
    //   const picURL = canvas.toDataURL();
    //   setDataUrl(picURL);
    //   console.log(picURL);
    //   LEFT_POSITION = "1800px";
    //   setPreview(true);
    // }
  };

  const checkConnection = () => {
    // console.log(points[activeIndex], activeIndex, points[activeIndex].length, showSave)
    if (points[activeIndex - 1].length > 2 || showSave) {
      // console.log(points[activeIndex -1], activeIndex, 'here')
      if (
        points[activeIndex - 1][0] == lastPoint ||
        points[activeIndex - 1][0] ==
          points[activeIndex - 1][points[activeIndex - 1].length - 1] ||
        showSave
      ) {
        return (
          <Button className="" onClick={saveSegmentedImage}>
            Save
          </Button>
        );
      }
    }

    // if(activeIndex==0 && edgeImageToMerge[activeVariable] && edgeImageToMerge[activeVariable].length>0){
    //   return (
    //     <Button className="margin--10px" onClick={previewSegmentedImage}>
    //       Preview
    //     </Button>
    //   );
    // }
  };

  const fillRest = async () => {
    // console.log(predictionData)
    if (predictionData) {
      let restCan = document.createElement("canvas");
      let ctx = restCan.getContext("2d");
      // console.log(points)
      // console.log(points.length)
      restCan.style.width = SIZEX + "px";
      restCan.style.height = SIZEY + "px";
      // var scale = 1;
      // window.devicePixelRatio;
      restCan.width = Math.floor(SIZEX * 1);
      restCan.height = Math.floor(SIZEY * 1);
      if (activeIndex >= 0) {
        for (let j = points.length - 2; j < points.length - 1; j++) {
          // console.log(j)
          // console.log(points[j])
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = LINE_WIDTH;
          ctx.fillStyle = pointsColor[j].colorPreview;
          // debugger
          ctx.strokeStyle = pointsColor[j].colorPreview;
          ctx.fill();
          ctx.stroke();
          ctx.save();
        }
      }
      ctx.restore();
      // console.log(restCan.toDataURL())
      let data = ctx.getImageData(0, 0, 600, 800);
      let img_ar = data.data;
      let f_ar = [];
      let count = 1;
      for (let i = 0; i < data.data.length; i += 4) {
        if (
          data.data[i] < 30 &&
          data.data[i + 1] < 30 &&
          data.data[i + 2] < 20
        ) {
          // console.log("yup")
          count += 1;
          data.data[i] = 0;
          data.data[i + 1] = 0;
          data.data[i + 2] = 0;
          data.data[i + 3] = 0;
        } else {
          if (
            predictionData[i] == 0 &&
            predictionData[i + 1] == 0 &&
            predictionData[i + 2] == 0
            // !(predictionData[i] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).r &&
            // predictionData[i+1] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).b &&
            // predictionData[i+2] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).g )
          ) {
          } else {
            data.data[i] = 0;
            data.data[i + 1] = 0;
            data.data[i + 2] = 0;
            data.data[i + 3] = 0;
          }
        }
      }
      ctx.putImageData(data, 0, 0);
      // alert('Reached here')
      // console.log(points[2], 'before')
      const pic = restCan.toDataURL();
      const initArr = edgeImageToMerge;
      // console.log(pic)
      // alert(pic)
      if (!initArr[activeVariable]) initArr[activeVariable] = [pic];
      else initArr[activeVariable].push(pic);

      // console.log(initArr, 'comes here')
      // alert(initArr[activeVariable])
      setEdgeImageToMerge(initArr);

      const temp = points;
      // console.log(points, 'before')
      let firstPop = temp.pop();
      let second = temp.pop();
      console.log("Pops", firstPop, second);
      temp.push([[]]);
      // console.log(temp.length, 'all_points')
      const tempColor = pointsColor;
      let color = tempColor.pop();

      // tempColor.push({
      //   colorSave: save,
      //   colorView: view,
      //   colorPreview: preview,
      // });
      // setActiveIndex(activeIndex + 1);
      // setActiveIndex(activeIndex)
      // setToggle(false);
      // console.log(po)
      console.log(
        pointsColor[pointsColor.length - 1],
        pointsColor.length,
        temp.length,
        "final_save"
      );
      // alert(pointsColor[pointsColor.length])
      setPenultimatePoint({});
      setLastPoint({});
      setPointsColor(tempColor);
      setPoints(temp);
      setToggle(false);
      setShowSave(true);
      setActiveIndex(activeIndex - 1);
      previewSegmentedImage();
      // setUpdate(!update)
      // setLastPoint({})
    }
  };

  const remove_from_seg = (old_seg) => {

    let newDummyImg = new Image();
    newDummyImg.src = old_seg;
    newDummyImg.crossOrigin = "anonymous";
    newDummyImg.onload = () => {
      URL.revokeObjectURL(newDummyImg.src);
      let old_seg_canvas = document.createElement("canvas");
      let old_seg_canvas_context = old_seg_canvas.getContext("2d");
      old_seg_canvas.style.width = SIZEX + "px";
      old_seg_canvas.style.height = SIZEY + "px";
      old_seg_canvas.width = Math.floor(SIZEX * 1);
      old_seg_canvas.height = Math.floor(SIZEY * 1);
      old_seg_canvas_context.scale(1, 1);
      // let adimg = new Image()
      // adimg.src = src
      // adimg.crossOrigin = "anonymous"
      old_seg_canvas_context.drawImage(
        newDummyImg,
        0,
        0,
        newDummyImg.naturalWidth,
        newDummyImg.naturalHeight,
        0,
        0,
        SIZEX,
        SIZEY
      );

      const old_seg_data = old_seg_canvas_context.getImageData(0, 0, 600, 800);

      let restCan = document.createElement("canvas");
      let ctx = restCan.getContext("2d");
      restCan.style.width = SIZEX + "px";
      restCan.style.height = SIZEY + "px";
      restCan.width = Math.floor(SIZEX * 1);
      restCan.height = Math.floor(SIZEY * 1);
      console.log(points.length)
      if (activeIndex >= 0) {
        for (let j = points.length - 2; j < points.length - 1; j++) {
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = 2;
          ctx.fillStyle = pointsColor[j].colorPreview;
          ctx.strokeStyle = pointsColor[j].colorPreview;
          ctx.fill();
          ctx.stroke();
          ctx.save();
        }
      }

      ctx.restore();
      let new_seg_data = ctx.getImageData(0, 0, 600, 800);
      let mySet = new Set()
      for (let i = 0; i < new_seg_data.data.length; i += 4) {
        mySet.add(old_seg_data.data[i])
        if (
          new_seg_data.data[i] > old_seg_data.data[i] - 30 &&
          new_seg_data.data[i] < old_seg_data.data[i] + 30 &&
          new_seg_data.data[i + 1] > old_seg_data.data[i + 1] - 30 &&
          new_seg_data.data[i + 1] < old_seg_data.data[i + 1] + 30 &&
          new_seg_data.data[i + 2] > old_seg_data.data[i + 2] - 30 &&
          new_seg_data.data[i + 2] < old_seg_data.data[i + 2] + 30
        ) {
          old_seg_data.data[i] = 0;
          old_seg_data.data[i + 1] = 0;
          old_seg_data.data[i + 2] = 0;
          old_seg_data.data[i + 3] = 0;
        }
      }

      old_seg_canvas_context.putImageData(old_seg_data, 0, 0);
      console.log(old_seg_canvas.toDataURL(),mySet, "final seg")
      person_data[TABLE][0][activeVariable] = old_seg_canvas.toDataURL();
      const initArr = edgeImageToMerge;
      setEdgeImageToMerge(initArr);
      const temp = points;
      let firstPop = temp.pop();
      let second = temp.pop();
      temp.push([[]]);
      console.log(temp.length, activeIndex, temp)
      const tempColor = pointsColor;
      let color = tempColor.pop();
      setActiveIndex(activeIndex - 1);
      setPenultimatePoint({});
      setLastPoint({});
      setPointsColor(tempColor);
      setPoints(temp);
      setToggle(false);
      setShowSave(true);

      previewSegmentedImage();
    }
  }

  const newFillRest = async (ignorePart) => {

    let allColors = []
    ignorePart.forEach(part => {
      let colors = part["value"].substring(5);
      if (colors == "204,204,204,255)") {
        colors = "0,0,0,255)"
      }
      colors = colors.split(",");
      console.log(colors)
      allColors.push(colors)
    })


    if (predictionData) {
      let restCan = document.createElement("canvas");
      let ctx = restCan.getContext("2d");
      // console.log(points)
      // console.log(points.length)
      restCan.style.width = SIZEX + "px";
      restCan.style.height = SIZEY + "px";
      // var scale = 1;
      // window.devicePixelRatio;
      restCan.width = Math.floor(SIZEX * 1);
      restCan.height = Math.floor(SIZEY * 1);
      if (activeIndex >= 0) {
        for (let j = points.length - 2; j < points.length - 1; j++) {
          // console.log(j)
          // console.log(points[j])
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            // ctx.moveTo(points[i - 1].x - OFFSET_X, points[i - 1].y - OFFSET_Y);
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = 2;
          ctx.fillStyle = pointsColor[j].colorPreview;
          // debugger
          ctx.strokeStyle = pointsColor[j].colorPreview;
          ctx.fill();
          ctx.stroke();
          ctx.save();
        }
      }
      ctx.restore();
      // console.log(restCan.toDataURL())
      let data = ctx.getImageData(0, 0, 600, 800);
      let img_ar = data.data;
      let f_ar = [];
      let count = 1;
      for (let i = 0; i < data.data.length; i += 4) {
        if (
          data.data[i] < 30 &&
          data.data[i + 1] < 30 &&
          data.data[i + 2] < 20
        ) {
          // console.log("yup")
          count += 1;
          data.data[i] = 0;
          data.data[i + 1] = 0;
          data.data[i + 2] = 0;
          data.data[i + 3] = 0;
        } else {
          for (let colorIndex = 0; colorIndex < allColors.length; colorIndex += 1) {
            if (
              !(
                predictionData[i] == parseInt(allColors[colorIndex][0].trim()) &&
                predictionData[i + 1] == parseInt(allColors[colorIndex][1].trim()) &&
                predictionData[i + 2] == parseInt(allColors[colorIndex][2].trim())
              )
              // !(predictionData[i] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).r &&
              // predictionData[i+1] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).b &&
              // predictionData[i+2] == hexToRgb(pointsColor[pointsColor.length - 1].colorSave).g )
            ) {
            } else {
              data.data[i] = 0;
              data.data[i + 1] = 0;
              data.data[i + 2] = 0;
              data.data[i + 3] = 0;
            }
          }
        }
      }

      ctx.putImageData(data, 0, 0);
      // alert('Reached here')
      // console.log(points[2], 'before')
      const pic = restCan.toDataURL();
      const initArr = edgeImageToMerge;
      // console.log(pic)
      // alert(pic)
      if (!initArr[activeVariable]) initArr[activeVariable] = [pic];
      else initArr[activeVariable].push(pic);

      // console.log(initArr, 'comes here')
      // alert(initArr[activeVariable])
      setEdgeImageToMerge(initArr);

      const temp = points;
      // console.log(points, 'before')
      let firstPop = temp.pop();
      let second = temp.pop();
      console.log("Pops", firstPop, second);
      temp.push([[]]);
      // console.log(temp.length, 'all_points')
      const tempColor = pointsColor;
      let color = tempColor.pop();

      // tempColor.push({
      //   colorSave: save,
      //   colorView: view,
      //   colorPreview: preview,
      // });
      // setActiveIndex(activeIndex + 1);
      // setActiveIndex(activeIndex)
      // setToggle(false);
      // console.log(po)
      console.log(
        pointsColor[pointsColor.length - 1],
        pointsColor.length,
        temp.length,
        "final_save"
      );
      // alert(pointsColor[pointsColor.length])
      setPenultimatePoint({});
      setLastPoint({});
      setPointsColor(tempColor);
      setPoints(temp);
      setToggle(false);
      setShowSave(true);
      setActiveIndex(activeIndex - 1);
      previewSegmentedImage();
      // setUpdate(!update)
      // setLastPoint({})
    }
  };

  const checkConnectionAndFillWithRest = () => {
    if (points[activeIndex - 1].length > 2)
      if (points[activeIndex - 1][0] == lastPoint || showSave) {
        return (
          <Button className="" onClick={fillRest}>
            <div style={{ width: "130px", whiteSpace: "break-spaces" }}>
              FILL BACKGROUND
            </div>
          </Button>
        );
      }
    // if(showSave){
    //   return (
    //     <Button className="" onClick={fillRest}>
    //       <div style={{width: '130px',whiteSpace: 'break-spaces' }}>
    //         IGNORE BACKGROUND
    //       </div>
    //     </Button>
    //   );
    // }
  };

  useEffect(() => {
    // fillRest()
    if (ignorePart.length > 0 && ignorePartActive) {
      newFillRest(ignorePart);
      setIgnorePart([]);
      setIgnorePartActive(false)
    }
  }, [ignorePart, ignorePartActive]);

  const deleteOldSeg = () => {
    if (points[activeIndex - 1].length > 2)
      if (points[activeIndex - 1][0] == lastPoint || showSave) {
        console.log(activeVariable, edgeImageToMerge[activeVariable], edgeImageToMerge, imageToMerge)
        if(person_data[TABLE][0][activeVariable]){
          console.log(person_data[TABLE][0][activeVariable])
          remove_from_seg(person_data[TABLE][0][activeVariable])
          // person_data[TABLE][0]["hair_seg"]
          console.log(person_data[TABLE][0][activeVariable])

        }
      }
  }

  const createOptions = () => {
    let options = []
    options.push({ value: "rgba(0,0,0,255)", label: "Background" })
    let optionsAllParts = allParts.filter(part => part["variable"] != activeVariable && part["variable"] !== "background_seg")
    optionsAllParts.forEach(optionPart => {
      options.push({ value: optionPart["color"], label: optionPart["name"] })
    })
    console.log(options)
    return options
  }

  const checkConnectionAndFillWithSome = () => {
    if (points[activeIndex - 1].length > 2)
      if (points[activeIndex - 1][0] == lastPoint || showSave) {
        return (
          // <Button className="" onClick={fillRest}>
          <div>
            <div style={{ width: "130px", whiteSpace: "break-spaces" }}>
              IGNORE
            </div>
            <div>
              <Select
                value={ignorePart}
                // onChange={(e) => setNaReason(e.target.value)}
                onChange={(e) => { console.log(e); setIgnorePart(e) }}
                isMulti={true}
                options={createOptions()}
                // name={naReason}
              >
              </Select>
            </div>
            <Button onClick={() => { setIgnorePartActive(true) }}>
              DONE
            </Button>
          </div>
          // </Button>
        );
      }
    // if(showSave){
    //   return (
    //     <Button className="" onClick={fillRest}>
    //       <div style={{width: '130px',whiteSpace: 'break-spaces' }}>
    //         IGNORE BACKGROUND
    //       </div>
    //     </Button>
    //   );
    // }
  };

  const deleteSeg = async () => {
    // alert(activeVariable)
    // await setSavingDelete(true)
    let allVariables = {};
    allVariables["id"] = id;
    allParts.map((part) => {
      allVariables[part["variable"]] = person_data[TABLE][0][part["variable"]];
    });
    allVariables["name"] = props.name;
    allVariables["last_updated"] = moment().utcOffset(+330);
    allVariables[activeVariable] = null;
    allVariables["seconds_spent"] = moment().diff(initTime, "seconds");
    setMutateType("deleted");
    allVariables["prediction_type"] = person_data[TABLE][0]["prediction_type"];
    allVariables["worst_case_labelled"] = person_data[TABLE][0]["worst_case_labelled"] ? "Labelled" : null;
    await save_all({
      variables: allVariables,
    });
    // await deleteSegmentation({
    //   seg_var: activeVariable,
    //   id: id,
    //   name: props.name,
    //   last_updated: moment().utcOffset(+330)
    // })
  };

  const checkConnectionAndPreview = () => {
    // alert(lastPoint)
    // if (points[activeIndex - 1].length > 2 || showSave)
    //   if (points[activeIndex - 1][0] == lastPoint|| showSave) {
    //     return (
    //       <Button className="margin--10px" onClick={previewSegmentedImage}>
    //         Preview
    //       </Button>
    //     );
    //   }
    // if(activeIndex==0 && edgeImageToMerge[activeVariable] && edgeImageToMerge[activeVariable].length>0){
    //   return (
    //     <Button className="margin--10px" onClick={previewSegmentedImage}>
    //       Preview
    //     </Button>
    //   );
    // }
  };

  const [showUseLabel, setShowUseLabel] = useState(false);

  const addAnotherPath = (
    save,
    view,
    preview,
    part = null,
    variable = null
  ) => {
    setShowSave(false);
    setShowUseLabel(true);
    setActiveVariable(variable);
    if (person_data && person_data[TABLE][0][variable]) {
      setImageToMerge({ ...imageToMerge, [variable]: "save" });
    } else {
      setImageToMerge({ ...imageToMerge, [variable]: "scratch" });
    }
    const temp = points;
    temp.push([]);
    const tempColor = pointsColor;
    tempColor.push({
      colorSave: save,
      colorView: view,
      colorPreview: preview,
      activeVariable: variable
    });
    setActiveIndex(activeIndex + 1);
    setPenultimatePoint({});
    setLastPoint({});
    setPointsColor(tempColor);
    setPoints(temp);
    setToggle(false);
  };

  const markAsNA = async () => {
    if (!naReason) {
      alert("Please mention the reason for marking the image as NA");
      return;
    }
    if (person_data) {
      // console.log("allParts: ", allParts)
      let flag = false;
      allParts.map((part) => {
        if (person_data[TABLE][0][part.variable] != null) {
          flag = true;
        }
      });
      if (naReason === "No") {
        await saveAppropriate({
          variables: {
            id: id,
            is_na: "No",
            reason_for_na: naReason,
            isLabelled: flag ? "Labelled" : "Not Labelled",
            last_updated: moment(),
            worst_case_labelled : (person_data[TABLE][0]["worst_case_labelled"]  ? "Labelled" : null)
          },
        });
      } else {
        await saveAppropriate({
          variables: {
            id: id,
            is_na: "Yes",
            reason_for_na: naReason,
            isLabelled: "Labelled",
            last_updated: moment(),
            worst_case_labelled : person_data[TABLE][0]["worst_case_labelled"] ? "Labelled" : null
          },
        });
        // nextImage();
      }
    }
  };

  const markAsGood = async () => {
    await savePredGood({
      variables: {
        id: id,
        is_pred_good: "Yes",
        isLabelled: "Labelled"
      },
    });
    if (person_data[TABLE][0]["is_pred_good"] == "No") {
      nextImage();
    }
  };

  const showRadioButton = () => {
    if (person_data && person_data[TABLE][0][activeVariable]) {
      return (
        <div style={{ backgroundColor: "#da9c40", display: "flex" }}>
          <div>
            <RadioGroup
              value={imageToMerge[activeVariable]}
              onChange={(e) => {
                if (
                  e.target.value == "save" &&
                  !person_data[TABLE][0][activeVariable]
                ) {
                  alert("No previous label is save for this class");
                  return;
                }
                setImageToMerge({
                  ...imageToMerge,
                  [activeVariable]: e.target.value,
                });
                // if(e.target.value == "save"){
                //   setListMergedImages([...listMergedImages, person_data[TABLE][0][activeVariable]])
                // }
              }}
            >
              <Radio value={"save"}>Use Saved Label</Radio>
              <Radio value={"scratch"}>Start from Scratch</Radio>
            </RadioGroup>
          </div>
          {/* </div> */}
          <div>
            <Button onClick={() => deleteSeg()}>Delete segmentation</Button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  function makeTopsPersonLink(){
    if(person_data[TABLE][0]['map_new_person'] && person_data[TABLE][0]['map_new_person']["map_tops_person_tool"] && person_data[TABLE][0]['map_new_person']["map_tops_person_tool"]["id"]){
      return `http://35.226.144.98:9000/name=sid/imageid=${person_data[TABLE][0]['map_new_person']["map_tops_person_tool"]["id"]}`
    }
    if(person_data[TABLE][0]['map_person'] && person_data[TABLE][0]['map_person']["map_tops_person_tool"] && person_data[TABLE][0]['map_person']["map_tops_person_tool"]["id"]){
      return `http://35.226.144.98:9000/name=sid/imageid=${person_data[TABLE][0]['map_person']["map_tops_person_tool"]["id"]}`
    }
    
  }

  function makePersonLink(){
    if(person_data[TABLE][0]['map_new_person'] && person_data[TABLE][0]['map_new_person']["map_tops_person_tool"] && person_data[TABLE][0]['map_new_person']["map_tops_person_tool"]["id"]){
      return `https://storage.cloud.google.com/labelling-tools-data/person_images_combined_white_padding/${person_data[TABLE][0]['map_new_person']['person_id']}}.png`;
    }
    if(person_data[TABLE][0]['map_person'] && person_data[TABLE][0]['map_person']["map_tops_person_tool"] && person_data[TABLE][0]['map_person']["map_tops_person_tool"]["id"]){
      return `https://storage.cloud.google.com/labelling-tools-data/person_images_combined_white_padding/${person_data[TABLE][0]['map_person']['person_id']}.png`;
    }
  }

  const showPersonImage = () => {
    // console.log(props)
    if(props.params){
      if(props.params.includes("showPerson")){
        return false
      }
    }
    return true;
  }

  return (
    <div>
      <div
        className="display--flex"
        style={{ padding: "0px 0px 40px 10px", flexDirection: "row" }}
      >
        <WorkingCanvas
          image={image}
          livePrediction={livePrediction}
          capturePoint={capturePoint}
          canvasRef={canvasRef}
          canvas_name={canvas_name}
          opacity={opacity}
          toggleImage={props.toggleImage}
          url={props.url}
          person_id={props.person_id}
          sizeX={SIZEX}
          sizeY={SIZEY}
          capturePointOutside={capturePointOutside}
          containerRef={containerRef}
        />
        {/* <img 
          style={{position: "absolute", top: "900px"}}
          src={image}
          width={SIZEX}
          height={SIZEY}
        /> */}

        <div style={{ padding: "20px" }}>
          {points[activeIndex].length > 0 && (
            <div>
              <Button
                className="margin--10px"
                style={{ height: "40px" }}
                onClick={lastPointClick}
              >
                Connect to first point
              </Button>
            </div>
          )}
          {points[activeIndex].length > 0 && (
            <div>
              <Button
                className="margin--10px"
                style={{ height: "40px" }}
                onClick={deletePointClick}
              >
                Delete last point
              </Button>
            </div>
          )}
        {activeVariable && person_data[TABLE][0][activeVariable] &&  (
            <div>
            <Button
              className="margin--10px"
              style={{ height: "40px" }}
              onClick={() => deleteOldSeg()}
            >
              Delete old segmentation
            </Button>
          </div>
          )}
          <div>
            {PRED_GOOD_AVAILABLE && (
              <div>
              <Button variantColor="blue" onClick={markAsGood}>
                {person_data && person_data[TABLE][0]["is_perfect"] == "Yes"
                  ? "Prediction Bad"
                  : "Prediction Good"}
              </Button>
              <div>{person_data && person_data[TABLE][0]["is_pred_good"] == "Yes"? "The prediction is good":""}</div>
              </div>
            )}
          </div>
          {(points[activeIndex].length > 0 || showSave) && checkConnection()}
          {(points[activeIndex].length > 0 || showSave) &&
            checkConnectionAndPreview()}
          {(points[activeIndex].length > 0 || showSave) &&
            checkConnectionAndFillWithRest()}
          <div>
            {IGNORE_OPTION && (points[activeIndex].length > 0 || showSave) &&
              checkConnectionAndFillWithSome()}
          </div>
          {savedImg && <div>The image is {mutateType} !</div>}
          {person_data && person_data[TABLE][0]["is_na"] == "Yes" && (
            <div>The image is marked as NA</div>
          )}
          <div style={{ display: "flex" }}>
            <div style={{ width: "300px" }}>
              <div style={{ width: "360px" }}>
                <div style={{ display: "flex" }}>
                  <Button onClick={() => setOpacity(opacity + 1)}>+</Button>
                  <div style={{ margin: "0px 10px" }}>
                    <b>{opacity}</b>
                  </div>
                  <Button onClick={() => setOpacity(opacity - 1)}>-</Button>
                  <Button onClick={() => nextImage()}>Next </Button>
                </div>
                <div>{showUseLabel && showRadioButton()}</div>
              </div>
              <div style={{ display: "flex", width: "380px" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {allParts.map((part) => (
                    // <div>
                    <div style={{ ...part["main_class"] }}>
                      <Button
                        onClick={() =>
                          addAnotherPath(
                            part["color"],
                            part["color"],
                            part["color"],
                            person_data[TABLE][0][part["variable"]],
                            part["variable"]
                          )
                        }
                        style={{
                          margin: "3px 0px 0px 0px",
                          backgroundColor: part["color"],
                          border:
                            activeVariable == part["variable"]
                              ? "5px solid black"
                              : "",
                          ...part["classes"],
                        }}
                        // className={part["classes"]}
                      >
                        <div>
                          {part["name"]}
                          {part["extra_info"] ? (
                            <div>{part["extra_info"]}</div>
                          ) : null}
                        </div>
                      </Button>
                      {part["desc"] && (
                        <div>
                          <b>{part["desc"]}</b>
                        </div>
                      )}
                    </div>
                    // </div>
                  ))}
                </div>
              </div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ width: "370px" }}>
              <div style={{ margin: "10px 5px" }}>
                Is the image <b> NA </b>? If yes, what is the <b>reason</b> for{" "}
                <b>marking image as NA</b> ?
              </div>
            </div>
            {
              <ChakraSelect
                value={naReason}
                onChange={(e) => setNaReason(e.target.value)}
                name={naReason}
              >
                <option value="No">No</option>
                <option value="black_white">Black And White</option>
                <option value="back_side_pose">
                  Back (90&deg; and more)
                </option>
                <option value="slightly_side_view">
                  SLightly Side View ( 15&deg; to less than 45&deg;)
                </option>
                <option value="slightly_side_view">
                  Side View ( 45&deg; to less than or equal 90&deg;)
                </option>
                <option value="super_wrinkled">
                  {/* Weird Pose (Sitting Down, Weirdly Bent){" "} */}
                  Super Wrinkled 
                </option>
                <option value="half_cut">Half cut image of cloth</option>
                <option value="occluded_image">
                  Cloth Occluded by accessory
                </option>
                <option value="folded_cloth">
                  Cloth is folded
                </option>
                <option value="multiple_cloth">Multiple Cloth</option>
                <option value="not_cloth">Not a  cloth Image</option>
                <option value="watermark_present">
                  Watermark over the cloth
                </option>
                <option value="cloth_over_manequinn">
                  Cloth over manequinn
                </option>
                {/* {allNaReason.map((op) => {
                      if (op["variable"] != part["variable"]) {
                        return (
                          <option value={op["variable"]}>{op["name"]}</option>
                        );
                      }
                      return null;
                    })} */}
              </ChakraSelect>
            }
            {
              <Button
                style={{ margin: "20px 0px 0px 0px" }}
                onClick={() => markAsNA()}
              >
                Save
              </Button>
            }
            {/* {person_data && <div><img src={`${OUTPUT_BACKGROUND}${person_data[TABLE][0]['map_person']['person_id']}.jpg`} width="384" height="512" /></div>} */}
            {/* <b>ASSIGN</b> */}
            {/* <div 
              // style={{ display: "flex" }}
            >
              {allParts.map((part) => (
                <div 
                style={{ display: "flex" }}
                >
                  <div
                    style={{
                      height: "40px",
                      lineHeight: "40px",
                      width: "70%",
                      backgroundColor: part["color"],
                    }}
                  >
                    {part["name"]}
                  </div>
                  <Select value={formFields[part['variable']]} onChange={onInputChange} name={part['variable']}>
                    <option value="">None</option>
                    {allParts.map((op) => {
                      if (op["variable"] != part["variable"]) {
                        return (
                          <option value={op["variable"]}>{op["name"]}</option>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </div>
              ))}
            </div> */}
          </div>
          {/* <img
            // size="500px"
            height="800px"
            width="600px"
            src={
              !props.toggleImage
                ? `${OUTPUT_BACKGROUND}${props.cloth_id}${OUTPUT_BACKGROUND_EXT}`
                : `https://storage.cloud.google.com/chan_helper/ashish/relabeling/TopCloth_coloured/${props.cloth_id}_topImage.png`
            }
            alt="No image in database"
            id="storage_image"
            style={{
              position: "absolute",
              top: "72px",
              left: "21px",
              zIndex: "-1",
              opacity: `${opacity * 0.1}`,
            }}
          /> */}
          <div>
            {/* <img
              height="512px"
              width="384px"
              src={
                person_data &&
                person_data[TABLE][0]
                ["map_related_cloth_rm"][
                  "map_cloth"
                ]["image_url"]
              }
              alt="No image in database"
              id="storage_image"
              // style={{position: "absolute",top: "72px",left: "21px",opacity: "0.5"}}
            /> */}
          </div>
          {/* <img src={props.url} width="384" height="512" /> */}
        </div>

        <div style={{ display: "flex" }}>
          <canvas
            className="frame-temp"
            id={"preview_canvas"}
            // onClick={capturePoint}
            // ref={canvasRef}
            style={{ display: "none" }}
          />
          {dataUrl && (
            <img src={dataUrl} style={{ width: SIZEX, height: SIZEY }} />
          )}
          <div>
            <div style={{ position: "absolute", height: SIZEX / 2 }}>
              {/* <img
              // style={{position: "absolute", top: "900px"}}
              src={image}
              width={SIZEX / 2}
              height={SIZEY / 2}
            /> */}
              <canvas
                className=""
                id="livePreview"
                // onClick={capturePoint}
                // ref={canvasRef}
                width={SIZEX / 2}
                height={SIZEY / 2}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  zIndex: "2",
                  display: "flex",
                }}
              />
            </div>

            <div>
              {/* <br/>
            <br/> */}
              <div>
                {/* {person_data && person_data[TABLE][0].isLabelled == "Labelled" && (<b>AFTER WORK ---{'>'} </b>)} */}
              </div>
              <div style={{ paddingTop: "405px", width: "250px" }}>
                <b>Prediction after work</b>
              </div>
              <div>
                <img
                  style={{
                    position: "absolute",
                    top: "500px",
                    marginLeft: "-125px",
                  }}
                  src={props.url}
                  width={SIZEX / 2}
                  height={SIZEY / 2}
                />
              </div>
            </div>
            <div>
            <img 
              src={`https://storage.cloud.google.com/labelling-tools-data/data_dumps/review_cloth/cloth_spurious_all/${person_data[TABLE][0]["cloth_id"]}.png`}  
              width={SIZEX}
              height={SIZEY}
              style={{
                position: "absolute",
                top: "70px",
                marginLeft: "180px",
              }}
              // alt={"needs to be labelled once."}
            />
          </div>
          </div>



          {/* {person_data && person_data[TABLE][0]["isLabelled"] == "Labelled" && (
            <div
              style={{
                position: "absolute",
                top: "900px",
                left: LEFT_POSITION,
              }}
            >
              <p>SHOW</p>

              <div
                style={{ display: "flex", width: "500px", flexWrap: "wrap" }}
              >
                  <Button 
                    onClick={() => showClicked("all")}
                  >
                    SHOW ALL
                  </Button>
                {allParts.map((part) => (
                  <div>
                    {person_data && person_data[TABLE][0][part["variable"]] && (
                      <Button onClick={() => showClicked(part["variable"])}>
                        {part["name"]}
                      </Button>
                    )}
                  </div>
                ))}
                <Button onClick={() => setOpacitySeg(opacitySeg + 1)}>+</Button>
                <div style={{ margin: "0px 10px" }}>
                  <b>{opacitySeg}</b>
                </div>
                <Button onClick={() => setOpacitySeg(opacitySeg - 1)}>-</Button>
              </div>
            </div>
          )} */}
        </div>
      </div>
      {false && showPersonImage() && <div className="display--flex">
        <iframe src={makeTopsPersonLink()} width="2000" height="2000"/>
        {/* <div className={"container"}>
          
          <img width="600" height="800" src={makePersonLink()} className={"item"}/>
          <img width="600" height="800" src={makePersonLink()} className={"item"}/>
        </div> */}
      </div>}
     
      <Review
        toggleImage={props.toggleImage}
        url={props.url}
        person_id={props.person_id}
        liveReview={liveReview}
        preview={preview}
        person_data={person_data}
        sizeX={SIZEX}
        size={SIZEY}
        showPart={showPart}
        setShowPart={setShowPart}
        allParts={allParts}
        reviewImage = {dataUrl}
      />
    </div>
  );
};

export default MapPoints;
