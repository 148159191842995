import React, { useEffect, useState } from "react";
import { Input, FormControl, FormLabel, Button } from "@chakra-ui/core";
import { GET_PRODUCT_ID_HELPER } from "../api/home";
import { useQuery } from "@apollo/react-hooks";
import {GET_NA_IDS, TABLE} from "../main";

{/* <option value="No">No</option>
<option value="black_white">Black And White</option>
<option value="back_side_pose">
  Back/Side Pose (90&deg; and more)
</option>
<option value="slightly_side_pose">
  SLightly Side Pose ( 45&deg; to less than 90&deg;)
</option>
<option value="weird_pose">
  Weird Pose (Sitting Down, Weirdly Bent){" "}
</option>
<option value="half_cut">Half cut image of person</option>
<option value="necklace_present">
  Body Occluded by necklace
</option>
<option value="occluded_image">
  Body Occluded by any object, accessory
</option>
<option value="cloth_as_accessory">
  Wearing Cloth as An accessory
</option>
<option value="multiple_people">Multiple People</option>
<option value="no_person">No person in Image</option>
<option value="watermark_present">
  Watermark over the person
</option> */}

let na_hash = {
  "No": "No",
  "black_white": "Black And white",
  "back_side_pose": "Back/Side Pose (90&deg; and more)",
  "slightly_side_pose": "SLightly Side Pose ( 45&deg; to less than 90&deg)",
  "weird_pose": "Weird Pose (Sitting, Weirdly Bent)",
  "half_cut": "Half cut image of cloth",
  "necklace_present": "Body Occluded by necklace",
  "occluded_image": "Body Occluded by any object, accessory",
  "cloth_as_accessory": "Wearing Cloth as AnAccessory",
  "multiple_people": "Multiple cloth",
  "no_person": "No cloth in Image",
  "watermark_present": "Watermark over the cloth"


}

function Helper(props) {
  const [startId, setStartId] = useState();
  const [num, setNum] = useState();
  const [findId, setFindId] = useState(false);
  const [lastId, setLastId] = useState();
  const [len, setLen] = useState();
  const [idsGroup, setIdsGroup] = useState({});

  // const { data: result, loading } = useQuery(GET_PRODUCT_ID_HELPER, {
  //   skip: !findId,
  //   variables: {
  //     current: parseInt(startId),
  //     limit: parseInt(num),
  //   },
  //   onCompleted(data) {
  //     if (data) {
  //       setLen(data["product_condition"].length);
  //       setLastId(parseInt(data["product_condition"][data["product_condition"].length - 1]["product_id"]));
  //       setFindId(false);
  //     }
  //   },
  // });

  const {data: ids, loadingIds} = useQuery(GET_NA_IDS,{
    skip: (!startId && !num && !findId),
    onCompleted(data){
      console.log(data)
      if(data) {
        setIdsGroup(transformNa(data[TABLE + '_aggregate']['nodes']))
      }
     
    },
    variables: {
      startId: startId,
      endId: num
    }
  })


  const onInputChange = (e) => {
    if (e.target.name == "num") {
      setNum(e.target.value);
    }
    if (e.target.name == "startId") {
      setStartId(e.target.value);
    }
  };

  // console.log(ids)

  const submit = () => {
    setFindId(true);
  };

  useEffect(()=> {
    if(loadingIds==false){
      setFindId(false)
    }
  }, [loadingIds])

  return (
    <div>
      <h3>Unlabelled ids</h3>
      <form style={{ width: "200px", margin: "auto" }}>
        <FormControl>
          <FormLabel htmlFor="startId">Start Id</FormLabel>
          <Input name="startId" value={startId} onChange={onInputChange} />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="num">End Id</FormLabel>
          <Input
            name="num"
            placeholder=""
            value={num}
            onChange={onInputChange}
          />
        </FormControl>
        {/* <Button onClick={submit}>FIND</Button> */}
        {/* {lastId && <div>The last Id is {lastId}</div>}
        {len && <div>Number Of Products: {len}</div>} */}
      </form>
      {loadingIds && <div>loading</div>}
        {/* {<div>{ids}</div>} */}
        {ids && ids[TABLE + '_aggregate'] && <div> Count : <b>{ids[TABLE + '_aggregate']['aggregate']['count']}</b></div>}
        <div style={{margin: '10px 20px'}} >
          {idsGroup && Object.keys(idsGroup).map((group) => (
            <div style={{margin: '40px 20px'}}>
              <b>{na_hash[group] ? na_hash[group] : group}</b>
              <div style={{display: "flex", width: '80%', margin: 'auto', flexWrap: 'wrap'}}>
              {idsGroup[group].map((id) =>(<div style={{margin: '10px 20px'}}>{id}</div>))}
              </div>
            </div>
            
          ))}
        {/* {ids && ids[TABLE + '_aggregate'] && (transformNa(ids[TABLE + '_aggregate']['nodes'])).map((id)=> (<div style={{margin: '10px 20px'}}>{id.id}</div>))} */}
        </div>
    </div>
  );
}

function transformNa(pair){
  let h = {}
  for(var i = 0; i < pair.length; i+=1){
    if(h[pair[i]["reason_for_na"]]===undefined){
      h[pair[i]["reason_for_na"]] = [pair[i]["id"]]
    }else{
      h[pair[i]["reason_for_na"]].push(pair[i]["id"])
    }
  }
  console.log(h)
  return h
}
export default Helper;
