import gql from "graphql-tag";

export const GET_IMAGE_URL = gql`
  query MyQuery($id: Int!) {
    tops_cloth_5_part_review(where: {id: {_eq: $id}}) {
        is_pred_good
        isLabelled
        id
        cloth_id
        torso_seg
        background_seg
        lua_seg
        lla_seg
        rua_seg
        rla_seg
        prediction_type
        seconds_spent
        name
        reason_for_na
        is_na
        worst_case_labelled
        map_person{
          person_id
          map_tops_person_tool{
            id
          }
        }
        map_new_person{
          person_id
          map_tops_person_tool{
            id
          }
        }

        straps_seg
        collar_seg
        torso_occluded_seg
        lla_occluded_seg
        lua_occluded_seg
        rla_occluded_seg
        rua_occluded_seg
    }
  }
`;


export const UPDATE_PERSON = gql`
  mutation MyMutation(
    $id: Int!,
    $torso_seg: String
    $lua_seg: String
    $lla_seg: String
    $rua_seg: String
    $rla_seg: String
    $background_seg: String
    $prediction_type: String
    $name: String
    $seconds_spent: Int
    $last_updated: timestamptz
    $worst_case_labelled: String
    $collar_seg: String
    $straps_seg: String
    $torso_occluded_seg: String
    $lla_occluded_seg: String
    $lua_occluded_seg: String
    $rla_occluded_seg: String
    $rua_occluded_seg: String
  ) {
    update_tops_cloth_5_part_review(
      where: { id: { _eq: $id } }
      _set: { 
          torso_seg: $torso_seg,
          lua_seg: $lua_seg,
          lla_seg: $lla_seg,
          rua_seg: $rua_seg,
          rla_seg: $rla_seg,
          background_seg: $background_seg,
          isLabelled: "Labelled",
          is_na: "No",
          reason_for_na: "No",
          prediction_type: $prediction_type,
          name: $name,
          seconds_spent: $seconds_spent
          last_updated: $last_updated
          worst_case_labelled: $worst_case_labelled
          is_pred_good: "No"
          collar_seg: $collar_seg
          straps_seg: $straps_seg
          torso_occluded_seg: $torso_occluded_seg
          lla_occluded_seg: $lla_occluded_seg
          lua_occluded_seg: $lua_occluded_seg
          rla_occluded_seg: $rla_occluded_seg
          rua_occluded_seg: $rua_occluded_seg
        }
    ) {
      affected_rows
    }
  }
`;

export const allParts = [
  {
    variable : "torso_seg",
    color: "rgba(0,255,255,255)",
    name: "TORSO",
    main_class: {width: "160px"}
  },
  
  {
    variable: "background_seg",
    color: "rgba(204,204,204,255)",
    name: "BACKGROUND",
    main_class: {width: "200px"}
  },
  {
    variable : "lua_seg",
    color: "rgba(255,255,0, 255)",
    name: "LEFT UPPER ARM"
  },
    {
    variable: "rua_seg",
    color: "rgba(0,255,0, 255)",
    name: "RIGHT UPPER ARM"
  },
  {
    variable: "lla_seg",
    color: "rgba(255,0,0, 255)",
    name: "LEFT LOWER ARM"
  },

  {
    variable: "rla_seg",
    color: "rgba(0,0,255, 255)",
    name: "RIGHT LOWER ARM"
  },

  // {
  //   variable: "straps_seg",
  //   color: "rgba(255,85,255, 255)",
  //   name: "STRAPS"
  // },

  {
    variable: "collar_seg",
    color: "rgba(55,55,55, 255)",
    name: "COLLAR",
    classes: {color: "white"}
  },

  {
    variable: "torso_occluded_seg",
    color: "rgba(170,85,0, 255)",
    name: "TORSO OCCLUDED",
    classes: {color: "white"}
  },

  {
    variable: "lla_occluded_seg",
    color: "rgba(255,85,255, 255)",
    name: "LEFT LOWER ARM OCCLUDED",
    classes: {color: "white"}
  },

  {
    variable: "lua_occluded_seg",
    color: "rgba(255,125,85, 255)",
    name: "LEFT UPPER ARM OCCLUDED",
    classes: {color: "white"}
  },

  {
    variable: "rla_occluded_seg",
    color: "rgba(123, 56, 67, 255)",
    name: "RIGHT LOWER ARM OCCLUDED",
    classes: {color: "white"}
  },

  {
    variable: "rua_occluded_seg",
    color: "rgba(158, 0, 89, 255)",
    name: "RIGHT UPPER ARM OCCLUDED",
    classes: {color: "white"}
  },
]


export const PRED_GOOD_AVAILABLE = true

export const TABLE = "tops_cloth_5_part_review"

export const OUTPUT_BACKGROUND = 'https://storage.googleapis.com/naman-bucket/dataset/newpersons/'

export const OUTPUT_BACKGROUND_EXT = '.jpg'

export const PREDICTION = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/stitching/`

export const PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/stitching/`

export const NEW_PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/stitching/`

export const PREDICTION_DRESS =  `https://storage.cloud.google.com/labelling-tools-data/data_dumps/dresses/worst_case_cloth_mask_800x600/`

export const PREDICTION_EXT = `.png`

export const CLOTH_DRESS = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/dresses/cloth-images_800x600/`

export const UPDATE_PRED_GOOD = gql`mutation MyMutation($id: Int, $is_pred_good: String, $isLabelled: String) {
  update_tops_cloth_5_part_review(where: {id: {_eq: $id}}, _set: {is_pred_good: $is_pred_good, isLabelled: $isLabelled}) {
    affected_rows
  }
}`;

export const RELATED = false

export const GET_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  tops_cloth_5_part_review_aggregate(distinct_on: id, where: {isLabelled: {_eq: "Not Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
    }
  }
}
`;

export const GET_WORSTCASE_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!, $worst_case_date: String!) {
  tops_cloth_5_part_review_aggregate(distinct_on: id, where: { id: {_gte: $startId, _lte: $endId}, worst_case_date: {_eq: $worst_case_date}}) {
    aggregate {
      count
    }
    nodes {
      id
      worst_case_labelled
      is_na
      reason_for_na
    }
  }
}
`;

export const WORST_CASE_QUERY = gql`
query  MyQuery($id: Int!, $worst_case_date: String) {
  tops_cloth_5_part_review(order_by: {id: asc}, where: {worst_case_date: {_eq: $worst_case_date}, id: {_gte: $id}}, limit: 2) {
    id
  }
}
`;

export const PASSWORD_QUERY = gql`
query MyQuery($password: String!) {
  password_for_tools_aggregate(where: {tool_name: {_eq: "CLOTH_TOPS"}, password: {_eq: $password}}) {
    aggregate {
      count
    }
    nodes {
      locked_ids
    }
  }
}
`;

export const UPDATE_PERSON_NA = gql`
  mutation MyMutation(
    $id: Int!,
    $is_na: String,
    $reason_for_na: String!,
    $isLabelled: String!,
    $last_updated: timestamptz,
    $worst_case_labelled: String
  ){
      update_tops_cloth_5_part_review(
        where: { id: { _eq: $id } }
        _set: {
          isLabelled: $isLabelled,
          is_na: $is_na,
          reason_for_na: $reason_for_na
          last_updated: $last_updated
          worst_case_labelled: $worst_case_labelled
        }
      ) {
        affected_rows
        returning {
          is_na
        }
      }
    }
`;

export const GET_NA_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  tops_cloth_5_part_review_aggregate(distinct_on: id, where: {is_na: {_eq: "Yes"}, isLabelled: {_eq: "Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
      reason_for_na
    }
  }
}
`;


export const IS_PERSON = false

export const IGNORE_OPTION =  true

export const PREDICTION_OPTION = true

export const LINE_WIDTH = 1

export const POINT_WIDTH = 1